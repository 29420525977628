<template>
  <div class="accordion-item" @mouseover="upHere = true" @mouseleave="upHere = false">
    <div class="accordion-item-header">
      <div class="header-buttons">
        <Button class="button active" @click="toggleExpand">
          <md-icon :name="expanded ? 'chevron-up' : 'chevron-down'" />
        </Button>
      </div>
      <div class="title">
        <div class="prefix">C</div>
        <span>CHAT</span>
      </div>
      <div v-if="upHere && !isPromptStreaming" class="header-buttons">
        <div class="actions">
          <Button class="button" :class="{ active }" :disabled="isPromptStreaming" title="Delete" @click="deleteChat">
            <md-icon name="delete" />
          </Button>
        </div>
      </div>
    </div>
    <div v-if="expanded" class="accordion-item-content">
      <div class="settings">
        <Multiselect
          v-model="localSettings.model"
          label="Model"
          :options="modelsOptions"
          :disabled="isPromptStreaming"
          :get-label-callback="id => getLabel(id)"
          @input="onChange"
        />
        <TextField v-model="localSettings.temperature" :disabled="isPromptStreaming" label="Temperature" type="number" @input="onChange" />
        <TextField v-model="localSettings.max_tokens" :disabled="isPromptStreaming" label="Max tokens" type="number" @input="onChange" />
        <div v-if="getFunctions(localSettings.model)">
          <Button color="primary" title="Functions" @click="toggleFunctions">
            <div>
              <MdIcon size="sm" name="function-variant" />
              {{ getFunctions(localSettings.model).filter(f => f.selected).length }} attached
              <MdIcon size="sm" :name="functionsExpanded ? 'menu-up' : 'menu-down'" />
            </div>
          </Button>
        </div>
        <Checkbox v-model="localSettings.includeHistory" :disabled="isPromptStreaming" label="Include History" @input="onChange" />
      </div>
      <div v-if="functionsExpanded" class="functions" :class="{ expanded: functionsExpanded }">
        <ul>
          <li v-for="func in getFunctions(localSettings.model)" :key="func.name" class="function">
            <Checkbox v-model="func.selected" @change="s => functionChecked(func.name, s)" />
            <span>
              <label> {{ func.name }} </label>({{ !!func.userDescritpion ? func.userDescritpion : func.description }})
            </span>
          </li>
        </ul>
      </div>
      <div class="prompt-body-content">
        <div v-for="(message, messageIndex) in localSettings.messages" :key="messageIndex" class="template-content">
          <div class="head">
            <span class="label">{{ message.role }}:</span>
          </div>
          <Code
            v-model="message.content"
            :read-only="!$hasPermission('prompts.write') || isPromptStreaming"
            :language="'plaintext'"
            class="editor"
            :auto-height="true"
            @input="onChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Button from '@/components/common/Button';
import MdIcon from '@/components/common/MdIcon';
import Code from '@/components/common/Code';
import Multiselect from '@/components/common/Multiselect';
import TextField from '@/components/common/TextField';
import Checkbox from '@/components/common/Checkbox';

export default {
  components: {
    Button,
    MdIcon,
    Code,
    Multiselect,
    TextField,
    Checkbox
  },
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      localSettings: {
        ...this.settings,
        functions: this.settings.functions ?? [],
        includeHistory: this.settings.includeHistory ?? false
      },
      expanded: false,
      functionsExpanded: false,
      upHere: false
    };
  },
  computed: {
    ...mapState({
      isPromptStreaming: state => state.prompts.sample.isRequestPending,
      active: state => !state.prompts.sample.isRequestPending,
      models: state => state.nlp.models || [],
      providers: state => state.nlp.providers || []
    }),
    modelsOptions() {
      return this.models.map(m => m.id);
    }
  },
  watch: {
    'localSettings.model': {
      handler() {
        this.localSettings.functions = this.getFunctions(this.localSettings.model);
        if (!this.localSettings.functions) {
          this.functionsExpanded = false;
        }
      }
    }
  },
  created() {
    const model = this.modelBackwardCompatibility(this.localSettings.model);

    if (model && this.localSettings.model !== model) {
      this.localSettings.model = model;
    }
  },
  methods: {
    modelBackwardCompatibility(candidate) {
      var value = this.models.find(m => m.id == candidate);

      if (value) {
        return value.id;
      }

      for (const md of this.models) {
        if (!md.config) {
          continue;
        }
        var config = JSON.parse(md.config);
        if (config.deployment == candidate) {
          return md.id;
        }
      }

      return null;
    },
    toggleFunctions() {
      this.functionsExpanded = !this.functionsExpanded;
    },
    getFunctions(modelId) {
      if (!modelId) {
        return [];
      }

      const model = this.models.find(m => m.id == modelId);

      if (!model) {
        return undefined;
      }

      const provider = this.providers?.find(p => p.name === model.provider);

      if (provider?.functions?.length) {
        return provider?.functions.map(f => ({
          ...f,
          selected: this.localSettings.functions?.includes(f.name) ?? false
        }));
      }

      return undefined;
    },
    functionChecked(name, checked) {
      if (checked) {
        this.localSettings.functions.push(name);
      } else {
        var index = this.localSettings.functions.indexOf(name);
        this.localSettings.functions.splice(index, 1);
      }

      this.$emit('change', { ...this.localSettings });
    },
    getLabel(id) {
      return this.models.find(m => m.id === id)?.name;
    },
    onChange() {
      this.$emit('change', { ...this.localSettings });
    },
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    deleteChat() {
      this.$emit('delete');
    }
  }
};
</script>

<style scoped lang="scss">
.accordion-item {
  .accordion-item-header {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    align-items: center;
    align-content: center;
    min-height: 50px;
    height: 100%;
    background-color: var(--theme-surface);
    border-bottom: 1px solid var(--theme-on-surface);
    padding: 0 10px;

    .header-buttons {
      display: flex;
      align-items: center;

      .actions {
        display: flex;
        padding-right: 5px;
      }
    }
  }

  .title {
    display: grid;
    gap: 5px;
    align-items: center;
    grid-template-columns: max-content 1fr;

    .prefix {
      color: var(--theme-surface);
      background-color: var(--theme-secondary);
      border: 1px solid;
      border-radius: 3px;
      font-family: 'Roboto';
      font-weight: 800;
      padding: 2px;
      user-select: none;
    }
  }

  .button {
    padding: 0px;
  }

  .active {
    cursor: pointer;
    :hover {
      background-color: var(--theme-on-surface);
      color: var(--theme-surface);
      border-radius: 2px;
    }
  }

  .accordion-item-content {
    padding: 10px;

    .settings {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;
    }

    .functions {
      display: none;
      gap: 5px;
      padding: 5px;
      padding: 10px;
      background-color: var(--theme-surface);

      .function {
        display: flex;
        align-content: flex-start;
        gap: 5px;
        font-size: 0.85rem;
        padding: 5px;
        padding-left: 0;
        min-width: 150px;

        label {
          font-weight: bold;
          color: var(--theme-on-surface-accent);
        }
      }
    }

    .expanded {
      display: flex;
      right: 0;
    }

    .prompt-body-content {
      padding: 10px;

      .template-content {
        margin: 10px 0;
        display: grid;
        gap: 10px;

        .label {
          font-weight: 500;
          font-size: 0.75rem;
          letter-spacing: 0.025em;
        }

        .editor {
          height: 200px;
        }

        .head {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}
</style>
