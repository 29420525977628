<template>
  <Modal :visible="true">
    <template #title> {{ category.id ? 'Edit' : 'Create' }} category </template>
    <div class="create-or-edit-category">
      <div class="row">
        <Textfield v-model="name" label="name" />
      </div>
      <div class="row">
        <Textfield v-model="description" label="Description" multiline />
      </div>
      <div class="row">
        <Multiselect v-model="tags" label="Tags" :multiple="true" :taggable="true" :options="tagOptions" />
      </div>
    </div>
    <template #footer>
      <Button @click="$emit('close')">Cancel</Button>
      <Button :disabled="!name.length" color="secondary" @click="onSubmit">{{ category.id ? 'Edit' : 'Create' }}</Button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/common/Modal.vue';
import Textfield from '@/components/common/TextField.vue';
import Multiselect from '@/components/common/Multiselect.vue';
import Button from '@/components/common/Button.vue';
export default {
  components: {
    Modal,
    Textfield,
    Button,
    Multiselect
  },
  props: {
    category: {
      type: Object,
      default: () => ({ name: '', description: '' })
    }
  },
  data() {
    return {
      name: this.category ? this.category.name : 'New category',
      description: this.category ? this.category.description : 'Awesome new category',
      tags: (this.category && this.category.tags) || [],
      tagOptions: []
    };
  },
  async created() {
    this.tagOptions = await this.$store.dispatch('library/getTags');
  },
  methods: {
    onSubmit() {
      this.$emit('submit', {
        ...(this.category ? this.category : {}),
        name: this.name,
        description: this.description,
        tags: this.tags
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.create-or-edit-category {
  padding: 20px;
  .row {
    margin-bottom: 15px;
  }
}
</style>
