<template>
  <div class="entity">
    <header>
      <div class="entity-name-wrapper">
        <Button v-if="localItem.id" class="button" @click="settingsShown = !settingsShown">
          <MdIcon :name="settingsShown ? 'chevron-up' : 'chevron-down'" />
        </Button>
        <label class="entity-name">
          {{ item && item.name }}
        </label>

        <div v-if="organizationsCollection.length" class="entity-organization">
          <div v-if="localItem && localItem.isGeneric" class="organization public">
            GENERIC
          </div>
          <div v-for="organization of organizationsNames" :key="organization" class="organization">
            {{ organization }}
          </div>
          <div v-for="category of categoriesNames" :key="category" class="organization category">
            {{ category }}
          </div>
        </div>
      </div>
      <div>
        <Button v-if="item.libraryType === 'drawing'" title="Download template" @click="download">
          <MdIcon name="download" />
        </Button>
      </div>
    </header>
    <div>
      <Settings v-if="settingsShown" :item="localItem" @cancel="onCancel" @submit="onSubmit" />
      <template v-else>
        <template v-if="!loading">
          <Prompt v-if="item && item.libraryType === 'prompt'" :item="localItem" @submit="onSubmit" />
          <Specification v-if="item && item.libraryType === 'specification'" :item="localItem" @submit="onSubmit" />
          <Drawing v-if="item && item.libraryType === 'drawing'" :item="localItem" @submit="onSubmit" />
        </template>
        <template v-else>
          <p-loading />
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Loading from '@/components/common/Loading';
import Specification from '../specifications/WithClaimSet.vue';
import Settings from './Settings.vue';
import Button from '@/components/common/Button';
import MdIcon from '@/components/common/MdIcon';
import Prompt from '../prompts/PromptContent.vue';
import Drawing from '../drawings/_CreateOrEdit.vue';
import httpClient from '@/utils/httpClient';

export default {
  components: {
    'p-loading': Loading,
    Prompt,
    Drawing,
    Specification,
    Settings,
    Button,
    MdIcon
  },
  props: {
    item: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      settingsShown: !this.item.id,
      localItem: this.item,
      loading: false
    };
  },
  computed: {
    ...mapState({
      organizationsCollection: s => s.organizations.collection ?? [],
      organizationsCollection: s => s.organizations.collection ?? [],
      categoriesCollection: s => s.library.categories ?? []
    }),
    organizationsNames() {
      if (!this.localItem || !this.organizationsCollection) {
        return [];
      }

      const organizations = this.localItem.organizations
        ? this.localItem.organizations.map(id => this.organizationsCollection.find(o => o.id === id)?.name ?? id)
        : [];

      if (!organizations.length) {
        return [];
      }

      return organizations;
    },
    categoriesNames() {
      if (!this.localItem || !this.categoriesCollection) {
        return [];
      }

      const categories = this.localItem.categories
        ? this.localItem.categories.map(id => {
            const category = this.categoriesCollection.find(c => c.id === id);
            if (category.parentId) {
              const parent = this.categoriesCollection.find(c => c.id === category.parentId)?.name ?? category.parentId;
              return `${parent} > ${category.name}`;
            }
            return category.name;
          })
        : [];
      if (!categories.length) {
        return [];
      }

      return categories;
    }
  },
  watch: {
    async item(nw, ow) {
      if (!nw.id) {
        this.settingsShown = true;
        this.localItem = this.item;
        return;
      }
      if (nw.id) {
        this.settingsShown = false;
      }

      this.loading = true;
      if (this.item.id) {
        this.localItem = await this.$store.dispatch(`${this.item.libraryType}s/getById`, this.item.id);
      }
      this.loading = false;
    }
  },
  async created() {
    await Promise.all([this.$store.dispatch('organizations/getCollection'), this.$store.dispatch('library/getCategories')]);
    if (this.item.id) {
      this.loading = true;
      this.localItem = await this.$store.dispatch(`${this.item.libraryType}s/getById`, this.item.id);
      this.loading = false;
    }
  },
  methods: {
    async onSubmit(e) {
      const payload = {
        ...this.localItem,
        ...e
      };
      const action = this.localItem.id ? 'update' : 'create';
      const newItem = await this.$store.dispatch(`${this.item.libraryType}s/${action}`, payload);
      this.localItem = newItem;
      this.settingsShown = false;
      this.$emit('update');
    },
    onCancel() {
      if (this.localItem.id) {
        this.settingsShown = false;
      } else {
        this.$emit('cancel');
      }
    },
    async download() {
      const token = await httpClient.get(`/api/drawings/${this.item.id}/token`);
      window.open(`/api/drawings/${this.item.id}/content?authToken=${token}`, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.entity {
  width: 100%;
  margin: 0 0.5rem;
  display: grid;
  grid-template-rows: max-content minmax(0, 1fr);
  grid-gap: 0.5rem;
  height: 100%;

  // &.settings {
  //   grid-template-rows: max-content max-content max-content;
  // }

  > header {
    background: var(--theme-surface);
    padding: 0.2rem 0.5rem;
    justify-content: space-between;
    align-items: center;
    display: flex;

    .entity-name-wrapper {
      grid-template-rows: auto auto;
      padding: 7px;
      align-items: flex-start;

      .entity-name {
        text-transform: uppercase;
        font-weight: 600;
        color: var(--theme-on-background-dark);
        padding: 0 5px;
        font-size: 1rem;
        cursor: pointer;
      }

      .button {
        padding: 0;
      }
    }

    .entity-organization {
      display: flex;
      flex-wrap: wrap;
      padding-left: 25px;

      .organization {
        display: flex;
        align-items: center;
        background-color: var(--theme-on-surface);
        color: black;
        margin-top: 2px;
        padding: 2px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 0.65rem;
        margin-right: 5px;
        margin-bottom: 2px;
        border-radius: 2px;
        font-weight: 600;
        letter-spacing: 0.02rem;
        user-select: none;
        cursor: auto;
        &.category {
          background-color: #5d4ce8;
        }

        &.public {
          background-color: var(--theme-primary);
          color: var(--theme-on-primary);
        }
      }
    }
  }
}
</style>
