<template>
  <section v-if="!isReady || isGetCollectionRequestPending" class="prompts">
    <p-loading />
  </section>

  <section v-else class="prompts">
    <header>
      <h1>Prompts</h1>
      <section class="action-wrapper">
        <router-link v-if="$hasPermission('prompts.write')" to="/prompts/create">+ new</router-link>
      </section>
    </header>

    <section>
      <p-list
        name="prompts"
        :data="filteredCollection"
        :total="total"
        :metadata="{
          name: { label: 'Name', width: 'minmax(0, 3fr)' },
          description: { label: 'Description', width: 'minmax(0, 3fr)' },
          tags: { label: 'Tags', width: 'minmax(100px, 2fr)' },
          organizations: { label: 'Organizations', width: 'minmax(100px, 2fr)', labelCallback: getOrganizationName },
          isGeneric: { label: 'Generic', width: '60px' },
          modifiedAt: { label: 'Last modification', width: '175px' }
        }"
        @edit="edit"
      >
        <template #name="{ value, item }">
          <router-link :to="`/prompts/${item.id}`">{{ value }}</router-link>
        </template>
        <template #__actions="{ item }">
          <p-button variant="text" title="Edit this prompt" @click="edit(item.id)"> &#9998;</p-button>
        </template>
        <template slot="filter">
          <div class="tags-wrapper">
            <Multiselect v-model="tags" :multiple="true" placeholder="Filter by tag..." :options="allTags" />
            <Multiselect v-model="organizations" :multiple="true" placeholder="Filter by organization..." :options="organizationsOptions" />
          </div>
        </template>
      </p-list>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Loading from './../common/Loading';
import Button from '@/components/common/Button';

import List from './../common/List';
import Multiselect from '@/components/common/Multiselect';

export default {
  components: {
    'p-list': List,
    'p-loading': Loading,
    'p-button': Button,
    Multiselect
  },
  data() {
    return {
      isReady: false,
      tags: [],
      organizations: []
    };
  },
  computed: {
    ...mapState({
      collection: s => s.prompts.collection,
      total: s => s.prompts.total,
      isGetCollectionRequestPending: s => s.prompts.isGetCollectionRequestPending,
      organizationsCollection: s => s.organizations.collection
    }),
    allTags() {
      const set = this.collection.reduce((acc, item) => {
        item.tags && item.tags.forEach(element => acc.add(element));

        return acc;
      }, new Set());
      return Array.from(set);
    },
    organizationsOptions() {
      return this.organizationsCollection.map(org => org.name);
    },
    organizationsMapping() {
      return this.organizationsCollection.reduce((acc, org) => {
        acc[org.id] = org.name;
        return acc;
      }, {});
    },
    filteredCollection() {
      return this.collection.filter(item => {
        if (this.organizations?.length > 0) {
          if (!item.organizations) {
            return false;
          }
          if (item.organizations.length === 0) {
            return false;
          }
          const orgNames = item.organizations.map(id => this.organizationsMapping[id]);
          const organizationsIntersecting = this.organizations.some(org => orgNames.includes(org));
          if (!organizationsIntersecting) {
            return false;
          }
        }
        if (this.tags.length === 0) {
          return true;
        }
        return this.tags.some(tag => item.tags.includes(tag));
      });
    }
  },

  async created() {
    await Promise.all([this.$store.dispatch('prompts/getCollection'), this.$store.dispatch('organizations/getCollection')]);
    this.isReady = true;
  },
  methods: {
    async edit(id) {
      this.$router.push({ path: `/prompts/${id}/edit` });
    },
    getOrganizationName(id) {
      return this.organizationsCollection.find(o => o.id === id)?.name ?? 'Unknown Organization';
    }
  }
};
</script>

<style scoped lang="scss">
.prompts {
  display: grid;
  padding: 0 0.5rem 0 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: max-content minmax(0, 1fr);
  grid-gap: 0.5rem;
  width: 100%;
  height: 100%;
  .tags-wrapper {
    width: 500px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }
  > header {
    padding: 0.5rem 0.5rem 0;
    display: flex;
    justify-content: space-between;

    box-sizing: border-box;

    h1 {
      color: var(--theme-on-surface);
      font-size: 1.5rem;
      font-weight: 700;
      margin-right: 0.25rem;
    }

    .action-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>
