<template>
  <div class="filters-wrapper">
    <div class="text-representation" @click="isModalVisible = !isModalVisible">
      {{ textRepresentation }}
    </div>
    <div v-if="isModalVisible" class="filters-dropdown">
      <div class="row">
        <h2>Active</h2>
        <div class="content">
          <span
            v-for="organisation in selectedOrganizations"
            :key="organisation.id"
            class="tag selected"
            @click="toggleOrganisation(organisation.id)"
          >
            {{ organisation.name }}</span
          >
          <span v-for="type in selectedTypes" :key="type" class="tag selected" @click="toggleTypes(type)"> {{ type }}</span>
          <span v-for="user in selectedUsers" :key="user.id" class="tag selected" @click="toggleUsers(user.id)"> {{ user.name }}</span>
          <span v-for="tag in selectedTags" :key="tag.id" class="tag selected" @click="toggleTags(tag.id)"> {{ tag.name }}</span>
        </div>
      </div>
      <div class="row">
        <h2>By clients</h2>
        <div class="content">
          <HiddenFilter
            :collection="unselectedOrganizations"
            :selected="filter.organization"
            :mapping="statistics.organization"
            @toggled="toggleOrganisation"
          />
        </div>
      </div>

      <div class="row">
        <h2>By type</h2>
        <div class="content">
          <span v-for="type in unselectedTypes" :key="type" class="tag" @click="toggleTypes(type)">{{ type }}({{ getStat('type', type) }})</span>
        </div>
      </div>
      <div class="row">
        <h2>Last edited by</h2>
        <div class="content">
          <HiddenFilter :collection="unselectedUsers" :selected="filter.author" :mapping="statistics.author" @toggled="toggleUsers" />
        </div>
      </div>
      <div class="row">
        <h2>Tags</h2>
        <div class="content">
          <HiddenFilter :collection="unselectedTags" :selected="filter.tag" :mapping="statistics.tag" @toggled="toggleTags" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import HiddenFilter from './HiddenFilter.vue';
export default {
  components: {
    HiddenFilter
  },
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isModalVisible: false,
      types: ['drawing', 'prompt', 'specification']
    };
  },
  computed: {
    ...mapState({
      organizations: state => state.organizations.collection,
      users: state => state.users.collection,
      statistics: state => state.library.statistics
    }),
    unselectedOrganizations() {
      return this.organizations.filter(organisation => !this.filter.organization.includes(organisation.id));
    },
    selectedOrganizations() {
      return this.organizations.filter(organisation => this.filter.organization.includes(organisation.id));
    },
    valueStrings() {
      const result = [...this.filter.types];

      return result;
    },
    unselectedTypes() {
      return this.types.filter(type => !this.filter.type.includes(type));
    },
    selectedTypes() {
      return this.types.filter(type => this.filter.type.includes(type));
    },
    mappedUsers() {
      if (!this.users) {
        return [];
      }
      return this.users.map(user => ({ name: `${user.firstName} ${user.lastName}`, id: user.email }));
    },
    unselectedUsers() {
      return this.mappedUsers.filter(user => !this.filter.author.includes(user.id));
    },
    selectedUsers() {
      return this.mappedUsers.filter(user => this.filter.author.includes(user.id));
    },
    unselectedTags() {
      if (!this.statistics || !this.statistics.tag) {
        return [];
      }
      return this.statistics.tag
        .filter(tag => !this.filter.tag.includes(tag._id))
        .map(tag => {
          return { name: tag._id, id: tag._id };
        });
    },
    selectedTags() {
      if (!this.statistics || !this.statistics.tag) {
        return [];
      }
      return this.statistics.tag
        .filter(tag => this.filter.tag.includes(tag._id))
        .map(tag => {
          return { name: tag._id, id: tag._id };
        });
    },
    textRepresentation() {
      const organizations = this.selectedOrganizations.map(organisation => organisation.name);
      const users = this.selectedUsers.map(user => user.name);
      const tags = this.selectedTags.map(tag => tag.name);
      const selected = [...organizations, ...this.selectedTypes, ...users, ...tags];
      return selected.length ? selected.join(', ') : 'All';
    }
  },
  async created() {
    await this.$store.dispatch('organizations/getCollection');
    await this.$store.dispatch('users/getCollection');
  },
  methods: {
    toggleOrganisation(organisationId) {
      const selected = [...this.filter.organization];
      const index = selected.indexOf(organisationId);
      if (index === -1) {
        selected.push(organisationId);
      } else {
        selected.splice(index, 1);
      }
      this.$emit('updated', { ...this.filter, organization: selected });
    },
    toggleTypes(type) {
      const selected = [...this.filter.type];
      const index = selected.indexOf(type);
      if (index === -1) {
        selected.push(type);
      } else {
        selected.splice(index, 1);
      }
      this.$emit('updated', { ...this.filter, type: selected });
    },
    toggleTags(tagId) {
      const selected = [...this.filter.tag];
      const index = selected.indexOf(tagId);
      if (index === -1) {
        selected.push(tagId);
      } else {
        selected.splice(index, 1);
      }
      this.$emit('updated', { ...this.filter, tag: selected });
    },
    toggleUsers(userId) {
      const selected = [...this.filter.author];
      const index = selected.indexOf(userId);
      if (index === -1) {
        selected.push(userId);
      } else {
        selected.splice(index, 1);
      }
      this.$emit('updated', { ...this.filter, author: selected });
    },
    getStat(type, id) {
      if (type === 'type') {
        if (!this.statistics.type) {
          return 0;
        }
        return this.statistics.type.find(t => t._id === id)?.count || 0;
      }
      if (type === 'author') {
        if (!this.statistics.author) {
          return 0;
        }
        return this.statistics.author.find(u => u._id === id)?.count || 0;
      }
      return 0;
    }
  }
};
</script>

<style lang="scss">
.filters-wrapper {
  position: relative;
  .text-representation {
    text-align: right;
    font-size: 12px;
    color: #5d4ce8;
  }
  .filters-dropdown {
    position: absolute;
    width: 100%;
    max-height: 600px;
    overflow: scroll;
    border-radius: 2px;
    padding: 20px;
    background-color: var(--theme-surface);
    box-sizing: border-box;
    border: 1px solid #e0e0e0;
    z-index: 1;
    h2 {
      font-size: 10px;
      font-weight: 400;
      text-transform: uppercase;
    }
    .content,
    .hidden-filter {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 0;
      .tag {
        display: flex;
        align-items: center;
        margin-right: 5px;
        background-color: #eceaff;
        border-radius: 12px;
        border: 1px solid #cec9f8;
        color: #0c0c0c;
        height: 18px;
        padding: 0px 10px;
        gap: 0px;
        border: 1px 0px 0px 0px;
        margin-bottom: 10px;
        font-size: 12px;
        cursor: pointer;
        &.selected {
          background-color: #5d4ce8;
          border-color: #5d4ce8;
          color: #fff;
        }
      }
    }
  }
}
</style>
