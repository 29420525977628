const EventEmitter = require('events');
import logger from '@/utils/logger';

export const jsonObjectToFormData = obj => {
  const body = new FormData();
  for (const key of Object.keys(obj)) {
    if (Array.isArray(obj[key])) {
      if (obj[key].length) {
        for (const entry of obj[key]) {
          body.append(key, entry);
        }
      } else {
        body.append(key, []);
      }
    } else {
      body.append(key, obj[key]);
    }
  }
  return body;
};
export class HttpClient extends EventEmitter {
  constructor(getAuthToken) {
    super();
    if (!getAuthToken) {
      throw new Error('getAuthToken function should be defined');
    }
    this.getAuthToken = getAuthToken;

    for (const method of ['get', 'post', 'put', 'patch', 'delete']) {
      this[method] = (url, body, stream, options) =>
        this.request(
          url,
          {
            ...options,
            method: method.toUpperCase(),
            body
          },
          stream
        );
    }
  }

  async stream(url, options = {}, eventReceivedCallback) {
    await this.request(
      url,
      {
        ...options,
        method: 'POST',
        body: options.body
      },
      true
    )
      .then(response => {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let partialChunk = '';
        const pattern = /event: (?<event>.+)(\ndata: (?<data>.+))?/gm;

        function processChunk(chunk) {
          const text = decoder.decode(chunk, { stream: true });
          const events = (partialChunk + text).split('\n\n');
          partialChunk = events.pop();

          events.forEach(event => {
            let match;
            while ((match = pattern.exec(event))) {
              const name = match.groups['event']?.trim();
              const data = match.groups['data']?.trim();
              try {
                if (data) {
                  data = data && JSON.parse(data);
                }

                eventReceivedCallback(name, data);
              } catch (error) {
                logger.error(`Event ${event} data is not object: ${data}`);
                logger.error(error);
              }
            }
          });
        }

        function read() {
          return reader.read().then(({ done, value }) => {
            if (done) {
              if (partialChunk) {
                throw new Error('Partial event stream received');
              }
              return;
            }
            processChunk(value);
            return read();
          });
        }

        return read();
      })
      .catch(error => {
        if (error.message !== 'BodyStreamBuffer was aborted') {
          throw error;
        }
      });
  }

  async request(url, args = {}, stream) {
    if (!url || !url.length) {
      throw new Error('Url is not defined');
    }

    if (args.json === undefined) {
      args.json = true;
    }

    const { json, ...options } = args;

    const authToken = this.getAuthToken();

    options.headers = {
      Authorization: authToken && `Bearer ${authToken}`,
      'user-time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
      Cache: 'no-cache',
      ...(options.headers || {})
    };

    if (json) {
      options.headers['Content-Type'] = 'application/json';
      if (options.body) {
        options.body = JSON.stringify(options.body);
      }
    }

    if (stream) {
      options.headers.Accept = 'text/event-stream';
      return fetch(url, options);
    }

    const response = await fetch(url, options);

    if (response.status < 200 || response.status > 300) {
      const payload = {
        url,
        response
      };
      this.emit(response.status, payload);
      return Promise.reject(payload);
    }

    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    }

    if (contentType && (contentType.includes('application/pdf') || contentType.includes('application/zip'))) {
      return response.blob();
    }

    return response.text();
  }
}

export default new HttpClient(() => localStorage.getItem('PATENT_HUB_AUTH_TOKEN'));
