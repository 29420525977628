<template>
  <div v-if="currentRun.response.length" ref="container" class="stream-content" @scroll="onScroll">
    <div v-for="(message, index) in currentRun.response" :key="message.key">
      <div v-if="message.type === 'form'" class="form">
        <div class="form-body">
          <span>
            <label class="prefix">{{ linkPrefixText(message) }}</label
            ><label> {{ message.name }}</label></span
          >
          <div class="variables">
            <FormBody :item="getLinkFromMessage(message)" :read-only="message.done || !isRequestPending" :values-only="true" :suggestions="[]" />
          </div>
        </div>
        <Button v-if="!message.done && isRequestPending" class="continue-button" @click="next(message)" @keyup.enter="next(message)">
          <MdIcon title="Continue" name="arrow-down-bold" size="sm" />continue</Button
        >
      </div>
      <div v-else-if="message.type === 'tool'" class="tool">
        <div class="header">
          <Button color="secondary" class="button" @click="toggleToll(message)">
            <div class="label">
              <MdIcon name="function-variant" size="sm" />
              <span class="label">{{ message.author }}</span>

              <MdIcon :name="message.expanded ? 'chevron-up' : 'chevron-down'" size="sm" />
            </div>
          </Button>
          <template v-if="!message.result">
            <div class="typing">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </template>
        </div>
        <div v-if="message.text" class="body" :class="{ expanded: message.expanded }">
          <div><span> ARGUMENTS:</span>{{ message.arguments }}</div>
          <div><span> PRESERVE IN CONVERSATION:</span> {{ !!message.cache }}</div>
          <div>
            <span> RESULT: </span><br />
            {{ message.text }}
          </div>
        </div>
      </div>
      <div v-else :class="message.author === 'CURRENT_USER' ? 'user' : 'model'" class="message">
        <span class="label" :title="message.system">
          <MdIcon v-if="message.author !== 'CURRENT_USER'" :name="responseType(message)" size="sm" />
          {{ message.author === 'CURRENT_USER' ? email : message.author }}
          <MdIcon v-if="message.retry && !message.error" title="retry" name="alert-circle-outline" color="warning" size="sm" />
          <MdIcon v-if="message.error" title="error" name="alert-circle-outline" color="error" size="sm" />
          <template v-if="isRequestPending && index === currentRun.response.length - 1">
            <div class="typing">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </template>
        </span>
        <div v-if="message.text">
          <span class="body" :class="{ retry: message.retry }">
            {{ message.text }}
          </span>
        </div>
      </div>
    </div>
    <div ref="scrollToMe"></div>
  </div>
  <div v-else-if="!isRequestPending" class="empty">
    <span>
      Click <Button color="primary" @click="$emit('play')"> <MdIcon title="Continue" name="fast-forward" size="sm"/></Button> to execute prompt chain
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MdIcon from '@/components/common/MdIcon';
import Button from '@/components/common/Button';
import FormBody from './body/FormBody.vue';

export default {
  components: {
    MdIcon,
    Button,
    FormBody
  },
  data() {
    return {
      followScroll: true,
      programmaticScroll: false
    };
  },
  computed: {
    ...mapState({
      currentRun: s => s.prompts.sample,
      email: s => s.identity.email,
      isRequestPending: s => s.prompts.sample.isRequestPending
    })
  },
  watch: {
    isRequestPending(nv) {
      if (nv) {
        this.followScroll = true;
      }
    },
    'currentRun.response.length': {
      handler: async function() {
        if (!this.followScroll) {
          return;
        }

        await this.$nextTick();
        this.scrollToBottom();
      }
    },
    'currentRun.response': {
      handler: async function() {
        if (!this.followScroll) {
          return;
        }

        await this.$nextTick();
        this.scrollToBottom();
      },
      deep: true
    }
  },
  methods: {
    toggleToll(toolMessage) {
      toolMessage.expanded = !toolMessage.expanded;
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (this.programmaticScroll) {
        this.resetProgrammaticScrollFlag();
        return;
      }

      if (scrollTop + clientHeight >= scrollHeight - 20) {
        this.followScroll = true;
      } else {
        this.followScroll = false;
      }
    },
    resetProgrammaticScrollFlag() {
      // Use a short delay to reset the flag
      setTimeout(() => {
        this.programmaticScroll = false;
      }, 100);
    },
    linkPrefixText(message) {
      const item = this.getLinkFromMessage(message);

      const indexInGroup = this.currentRun.chain.filter(l => l.type === item.type).indexOf(item);
      return `${item.type.substring(0, 1).toUpperCase()}${indexInGroup + 1}`;
    },
    getLinkFromMessage(message) {
      return this.currentRun?.chain?.find(l => l.name === message.name && l.type === message.type);
    },
    async next(message) {
      message.done = true;
      await this.$store.dispatch('prompts/sample/continue');
    },
    responseType(m) {
      if (m.type === 'template') {
        return 'code-block-braces';
      }

      if (m.type === 'query') {
        return 'database-search';
      }

      return 'robot-confused';
    },
    scrollToBottom() {
      const container = this.$refs.container;
      if (!container) {
        return;
      }

      this.programmaticScroll = true;
      container.scrollTop = container.scrollHeight;
    }
  }
};
</script>

<style scoped lang="scss">
.empty {
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  overflow-y: hidden;

  span {
    font-size: 1rem;
  }
}

.stream-content {
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: pre-line;
  height: 100%;
  font-size: 12px;

  .form {
    padding: 0.75rem 1rem 0.75rem 1.25rem;
    display: grid;
    grid-template-rows: 1fr max-content;
    gap: 5px;

    label {
      text-transform: uppercase;
      font-weight: 500;
      padding: 2px;
    }

    .prefix {
      color: black;
      background-color: white;
      font-weight: 700;
      font-size: 16;
      border-radius: 2px;
    }

    .form-body {
      background-color: var(--theme-surface);
      padding: 15px;
      border-radius: 3px;

      span {
        user-select: none;
      }

      .variables {
        margin-top: 10px;
        border: 1px solid var(--theme-primary);
        padding: 7px;
        padding-bottom: 0;
        border-radius: 3px;
      }

      span {
        font-size: 14px;
      }
    }
  }

  .tool {
    padding: 0.75rem 1rem 0 1.25rem;
    padding-right: 15%;
    $dot-width: 5px;
    $dot-color: var(--theme-primary);
    $speed: 1.5s;

    .header {
      display: flex;
      align-items: center;
      gap: 3px;

      .typing {
        position: relative;

        span {
          content: '';
          animation: blink $speed infinite;
          animation-fill-mode: both;
          height: $dot-width;
          width: $dot-width;
          background: $dot-color;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 50%;

          &:nth-child(2) {
            animation-delay: 0.2s;
            margin-left: $dot-width * 1.5;
          }

          &:nth-child(3) {
            animation-delay: 0.4s;
            margin-left: $dot-width * 3;
          }
        }
      }

      @keyframes blink {
        0% {
          opacity: 0.1;
        }

        20% {
          opacity: 1;
        }

        100% {
          opacity: 0.1;
        }
      }

      .button {
        padding: 3px;
        align-items: center;
        align-self: center;
      }
    }

    .label {
      display: inline-flex;
      align-items: center;
      gap: 5px;
      font-size: 0.7rem;
      font-style: italic;
    }

    .body {
      display: none;
      background-color: var(--theme-surface);
      padding: 9px;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      text-align: left;
      line-height: 1.3rem;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;

      span {
        font-weight: 700;
      }

      &.retry {
        font-style: italic;
      }

      &.expanded {
        display: inline-block;
      }
    }
  }

  .message {
    padding: 0.75rem 1rem 0.75rem 1.25rem;
    padding-right: 15%;
    $dot-width: 5px;
    $dot-color: var(--theme-primary);
    $speed: 1.5s;

    .typing {
      position: relative;

      span {
        content: '';
        animation: blink $speed infinite;
        animation-fill-mode: both;
        height: $dot-width;
        width: $dot-width;
        background: $dot-color;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;

        &:nth-child(2) {
          animation-delay: 0.2s;
          margin-left: $dot-width * 1.5;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
          margin-left: $dot-width * 3;
        }
      }
    }

    @keyframes blink {
      0% {
        opacity: 0.1;
      }

      20% {
        opacity: 1;
      }

      100% {
        opacity: 0.1;
      }
    }

    &.user {
      text-align: right;
      padding-left: 25%;
      padding-right: 5px;
    }

    .label {
      display: inline-flex;
      align-items: center;
      gap: 5px;
      font-size: 0.7rem;
      font-style: italic;
      color: var(--theme-on-background-accent);
    }

    .body {
      display: inline-block;
      background-color: var(--theme-surface);
      padding: 9px;
      border-radius: 6px;
      text-align: left;
      line-height: 1.3rem;

      &.retry {
        font-style: italic;
      }
    }
  }
}
</style>
